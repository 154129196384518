exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-posts-1-md": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/blog/blog/posts/1.md" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-posts-1-md" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-posts-2-md": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/blog/blog/posts/2.md" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-posts-2-md" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-posts-3-md": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/blog/blog/posts/3.md" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-posts-3-md" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-posts-4-md": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/blog/blog/posts/4.md" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-posts-4-md" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-posts-5-md": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/blog/blog/posts/5.md" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-posts-5-md" */)
}

